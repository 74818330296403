/*--------------------------------------------------------------------------*
 * mv
 *--------------------------------------------------------------------------*/
$(function(){
    $('#js-mv').slick({
        arrows: false,
        dots: true,
        cssEase: 'ease-in',
        fade: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: true,
        lazyLoad: 'progressive'
    });
});


/*--------------------------------------------------------------------------*
 * works
 *--------------------------------------------------------------------------*/
$(function() {
    $('#js-works').slick({
        arrows: true,
        dots: false,
        cssEase: 'ease-in-out',
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        lazyLoad: 'progressive',
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },{
            breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '50px',
                    swipe: true,
                }
            }
        ]
    });
});
